import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const AddViolationPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players/:id/suspensions">
      <AddViolationRoute path="/add-violation" />
    </Router>
  );
};

interface AddViolationRouteProps extends RouteComponentProps {
  id?: string;
}

const AddViolationRoute: React.FC<AddViolationRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Add violation" />
      <AddSuspensionPoint />
    </Layout>
  );
};

export default AddViolationPage;
